import React, { useState, useEffect } from "react";
import {
  Drawer,
  Typography,
  Button,
  Form,
  Input,
  Select,
  Upload,
  Spin,
  Pagination,
  message,
} from "antd";
import {
  PictureOutlined,
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { createReel, updateReel } from "../requests";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../graphql/queries";
import useUpload from "hooks/use-upload/index";

const { Title } = Typography;
const { Option } = Select;

const EditReel = ({ showModal, setShowModal, editingReel, refetch }) => {
  // Subida de archivos
  const { upload } = useUpload();
  const [uploadLoading, setUploadLoading] = useState(false);

  // Formulario de Ant
  const [form] = Form.useForm();

  // Estados para paginación y búsqueda
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  // Estado para manejar productos seleccionados y producto temporal
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductToAdd, setSelectedProductToAdd] = useState(null);

  // Query para obtener productos con paginación y filtro
  const {
    data,
    loading,
    refetch: refetchProducts,
  } = useQuery(GET_PRODUCTS, {
    variables: {
      params: { page, pageSize },
      filters: {
        title: searchText,
        sku: searchText,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res?.products?.info?.pages) {
        setTotalPages(res.products.info.pages);
      }
    },
    onError: (err) => {
      console.error("❌ Error en GraphQL:", err);
    },
  });

  // Cargar datos cuando se abre el Drawer
  useEffect(() => {
    if (showModal) {
      refetchProducts(); // Forzar recarga de productos
      form.resetFields();

      if (editingReel) {
        // Cargar productos asociados
        const associatedProducts = Array.isArray(editingReel.products)
          ? editingReel.products.map((p) => ({
              id: p._id || p.id,
              title: p.title,
              sku: p.sku,
            }))
          : [];
        setSelectedProducts(associatedProducts);

        form.setFieldsValue({
          title: editingReel.title,
          description: editingReel.description || "",
          videoUrl: editingReel.videoUrl,
          snapshotUrl: editingReel.snapshot || "",
          status: editingReel.status || "active",
          type: editingReel.type || "thc",
        });
      } else {
        form.setFieldsValue({ status: "inactive", type: "thc" });
        setSelectedProducts([]);
      }
    }
  }, [showModal, editingReel, form, refetchProducts]);

  // Manejar la búsqueda: cada vez que cambia el texto, volvemos a la página 1
  useEffect(() => {
    setPage(1);
  }, [searchText]);

  // Al cambiar de página
  const handleChangePage = (newPage) => {
    setPage(newPage);
    refetchProducts({
      variables: {
        params: { page: newPage, pageSize },
        filters: { title: searchText, sku: searchText },
      },
    });
  };

  // Añadir producto a lista de seleccionados
  const handleAddProduct = () => {
    if (selectedProductToAdd && data?.products?.results) {
      const product = data.products.results.find(
        (p) => p.id === selectedProductToAdd
      );
      if (product && !selectedProducts.some((p) => p.id === product.id)) {
        setSelectedProducts([
          ...selectedProducts,
          { id: product.id, title: product.title, sku: product.sku },
        ]);
      }
      setSelectedProductToAdd(null);
    }
  };

  // Eliminar producto de lista de seleccionados
  const handleRemoveProduct = (productId) => {
    setSelectedProducts(selectedProducts.filter((p) => p.id !== productId));
  };

  // Subida de video
  const handleVideoUpload = async ({ fileList }) => {
    if (fileList[0]) {
      setUploadLoading(true);
      try {
        const fileName = `reels/videos/${new Date().toISOString()}/${
          fileList[0]?.name
        }`;
        const url = await upload(fileList[0].originFileObj, fileName);
        form.setFieldsValue({ videoUrl: url });
        message.success("Video subido correctamente.");
      } catch (error) {
        message.error("Error al subir el video.");
      } finally {
        setUploadLoading(false);
      }
    }
  };

  // Subida de imagen (snapshot)
  const handleImageUpload = async ({ fileList }) => {
    if (fileList[0]) {
      setUploadLoading(true);
      try {
        const fileName = `reels/snapshots/${new Date().toISOString()}/${
          fileList[0]?.name
        }`;
        const url = await upload(fileList[0].originFileObj, fileName);
        form.setFieldsValue({ snapshotUrl: url });
        message.success("Imagen subida correctamente.");
      } catch (error) {
        message.error("Error al subir la imagen.");
      } finally {
        setUploadLoading(false);
      }
    }
  };

  // Guardar (crear / actualizar) el Reel
  const onFinish = async (values) => {
    try {
      // Enviar SOLO los IDs de los productos, para que el backend pase la validación isMongoId()
      const productIds = selectedProducts.map((p) => p.id);

      if (editingReel) {
        // Editar reel existente
        await updateReel(editingReel._id, {
          ...values,
          products: productIds,
        });
        message.success("Reel actualizado con éxito.");
      } else {
        // Crear reel nuevo
        await createReel({
          ...values,
          products: productIds,
        });
        message.success("Reel creado con éxito.");
      }
      refetch(); // Refrescar la lista de reels
    } catch (error) {
      message.error("Error al guardar los datos.");
    } finally {
      onClose();
    }
  };

  // Cerrar el Drawer
  const onClose = () => {
    setShowModal(false);
    form.resetFields();
  };

  return (
    <Drawer
      title={
        <Title level={3} style={{ margin: 0 }}>
          {editingReel ? "Editar Reel" : "Agregar Reel"}
        </Title>
      }
      width={400}
      onClose={onClose}
      open={showModal}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ status: "inactive", type: "thc" }}
      >
        {/* Título */}
        <Form.Item
          label="Título"
          name="title"
          rules={[{ required: true, message: "Por favor, ingresa el título." }]}
        >
          <Input placeholder="Título del reel" />
        </Form.Item>

        {/* Descripción */}
        <Form.Item label="Descripción" name="description">
          <Input.TextArea placeholder="Descripción del reel" />
        </Form.Item>

        {/* Estado */}
        <Form.Item
          label="Estado"
          name="status"
          rules={[
            { required: true, message: "Por favor, selecciona un estado." },
          ]}
        >
          <Select placeholder="Selecciona un estado">
            <Option value="active">Activo</Option>
            <Option value="inactive">Inactivo</Option>
          </Select>
        </Form.Item>

        {/* Tipo */}
        <Form.Item
          label="Tipo"
          name="type"
          rules={[
            { required: true, message: "Por favor, selecciona un tipo." },
          ]}
        >
          <Select placeholder="Selecciona un tipo">
            <Option value="thc">THC</Option>
            <Option value="cbd">CBD</Option>
          </Select>
        </Form.Item>

        {/* Subir Video */}
        <Form.Item label="Subir Video">
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            accept="video/*"
            onChange={handleVideoUpload}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Subir Video</Button>
            {uploadLoading && <Spin style={{ margin: 10 }} />}
          </Upload>
        </Form.Item>
        <Form.Item name="videoUrl">
          <Input placeholder="URL del video" />
        </Form.Item>

        {/* Subir Snapshot (Imagen) */}
        <Form.Item label="Subir Snapshot (Imagen)">
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            accept="image/*"
            onChange={handleImageUpload}
            showUploadList={false}
          >
            <Button icon={<PictureOutlined />}>Subir Snapshot</Button>
            {uploadLoading && <Spin style={{ margin: 10 }} />}
          </Upload>
        </Form.Item>
        <Form.Item name="snapshotUrl">
          <Input placeholder="URL del snapshot" />
        </Form.Item>

        {/* Sección de productos (solo si editamos un reel) */}
        {editingReel && (
          <>
            <Form.Item label="Seleccionar un Producto (Autocomplete)">
              <div style={{ display: "flex", gap: "10px" }}>
                <Select
                  showSearch
                  style={{ flex: 1, maxWidth: "250px" }}
                  placeholder="Escribe para filtrar por Título / SKU"
                  value={selectedProductToAdd}
                  onSearch={(val) => setSearchText(val)}
                  onChange={setSelectedProductToAdd}
                  filterOption={false}
                  loading={loading}
                >
                  {data?.products?.results?.map((product) => (
                    <Option key={product.id} value={product.id}>
                      {product.title} - {product.sku}
                    </Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={handleAddProduct}
                  disabled={!selectedProductToAdd}
                />
              </div>
              <Pagination
                current={page}
                total={(totalPages || 1) * pageSize}
                pageSize={pageSize}
                onChange={handleChangePage}
                style={{ marginTop: 10, textAlign: "center" }}
              />
            </Form.Item>

            <Form.Item label="Productos Asociados">
              {selectedProducts.length === 0 ? (
                <p style={{ color: "gray" }}>No hay productos seleccionados</p>
              ) : (
                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  {selectedProducts.map((product) => (
                    <li
                      key={product.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px 0",
                      }}
                    >
                      <span>
                        {product.title} - {product.sku}
                      </span>
                      <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveProduct(product.id)}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </Form.Item>
          </>
        )}

        {/* Botón de submit */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {editingReel ? "Guardar Cambios" : "Crear Reel"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditReel;
